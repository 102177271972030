import { AggregateClassDto, SeasonDto } from "@justraviga/classmanager-sdk";

import { useFamilyCoursePreview } from "../../data/useFamilyCoursePreview";
import { formatDate } from "../../intlFormatter";
import { getSeasonTimeFrame } from "../../seasonUtils";
import { useGenericComponents } from "../GenericComponentsProvider";
import { HorizontalSeparatorComponent } from "../interfaces";
import { CoursePreview } from "../modules/customer/coursePreview/CoursePreview";

export const FamilyCoursePreview = ({
  course,
  season,
  HorizontalSeparator,
}: {
  course: AggregateClassDto;
  season: SeasonDto;
  HorizontalSeparator: HorizontalSeparatorComponent;
}) => {
  const { Icon, View } = useGenericComponents();

  const { room, location, staff } = useFamilyCoursePreview(course);

  const { weeksCount } = getSeasonTimeFrame(season);

  return (
    <View className={"pb-10"}>
      <CoursePreview.Name course={course.entity} />
      <CoursePreview.Time course={course.entity} Icon={Icon} isMobile={false} />
      <HorizontalSeparator spacing={4} />
      <CoursePreview.RowItem
        icon={"calendarClearOutline"}
        text={season.name}
        weight={"600"}
        color={"900"}
        Icon={Icon}
      />
      <CoursePreview.RowPair
        leftText={"Start date"}
        rightText={formatDate(season.startAt, "dayMonthYear")}
      />
      <CoursePreview.RowPair
        leftText={"End date"}
        rightText={formatDate(season.endAt, "dayMonthYear")}
      />
      <CoursePreview.RowPair
        leftText={"Length"}
        rightText={`${weeksCount} weeks`}
      />
      {location && room && (
        <>
          <HorizontalSeparator spacing={4} />
          <CoursePreview.LocationAndRoom
            location={location}
            room={room}
            Icon={Icon}
          />
        </>
      )}
      {course.entity.staffId && (
        <>
          <HorizontalSeparator spacing={4} />
          <CoursePreview.Teacher
            staff={staff}
            Icon={Icon}
            title={"Teacher/Instructor"}
          />
        </>
      )}
      <HorizontalSeparator spacing={4} />
      <CoursePreview.Lessons
        classId={course.entity.id}
        Icon={Icon}
        iconName={"calendarOutline"}
      />
      <HorizontalSeparator spacing={4} />
      <CoursePreview.Description
        course={course.entity}
        Icon={Icon}
        title={"More information"}
        showIcon={false}
      />
    </View>
  );
};
