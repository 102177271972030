import {
  AggregateClassDto,
  SeasonDto,
  StudentDto,
} from "@justraviga/classmanager-sdk";

import { displayLessonTimes } from "../../../classUtils";
import { cn } from "../../../cssUtils";
import { displayStudentNames } from "../../../studentUtils";
import { useGenericComponents } from "../../GenericComponentsProvider";
import { AvatarGroup } from "../../ui/AvatarGroup";
import { Chip } from "../../ui/Chip";

export interface FamilyLessonInstance {
  classId: string;
  date: string;
  time: string;
  type: "lesson" | "trial" | "makeUp";
  cancelled: boolean;
  students: Array<StudentDto>;
}

export const FamilyLessonCard = ({
  lesson,
  goToCourse,
  course,
  season,
}: {
  lesson: FamilyLessonInstance;
  goToCourse: (course: AggregateClassDto, season: SeasonDto) => void;
  course: AggregateClassDto;
  season: SeasonDto;
}) => {
  const { Pressable, View, Text } = useGenericComponents();

  const isAlternativeColor =
    ["trial", "makeUp"].includes(lesson.type) || lesson.cancelled;

  return (
    <Pressable onClick={() => goToCourse(course, season)}>
      <View
        className={cn(
          "rounded py-2 px-3",
          isAlternativeColor
            ? "bg-white border border-grey-300"
            : "bg-brand-100",
        )}>
        <View className="flex flex-row items-center justify-between space-x-2">
          <View className={"flex flex-1 flex-row space-x-2 items-center"}>
            <Text
              className="flex-shrink text-body-600 font-semibold text-grey-900"
              truncate={true}>
              {course.entity.name}
            </Text>
            <View>
              {lesson.type === "trial" && (
                <Chip label={"Trial"} variant={"neutral"} size={"sm"} />
              )}
              {lesson.type === "makeUp" && (
                <Chip label={"Make up"} variant={"neutral"} size={"sm"} />
              )}
              {lesson.cancelled && (
                <Chip label={"Cancelled"} variant={"danger"} size={"sm"} />
              )}
            </View>
          </View>

          <View className="flex flex-row justify-end">
            <AvatarGroup
              list={lesson.students.sort((a, b) =>
                a.firstname.localeCompare(b.firstname),
              )}
              size={"xs"}
            />
          </View>
        </View>

        <View className="flex flex-row items-center justify-start space-x-4 mt-1">
          <View className={"flex-grow"}>
            <Text className="text-label-400 text-grey-900 leading-tight whitespace-nowrap">
              {displayLessonTimes(course.entity)}
            </Text>
          </View>
          <View className={"flex-initial"}>
            <Text
              className="text-right text-label-400 leading-tight text-grey-900"
              truncate={true}>
              {displayStudentNames(lesson.students)}
            </Text>
          </View>
        </View>
      </View>
    </Pressable>
  );
};
