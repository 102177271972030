import { InvitationStatus } from "@justraviga/classmanager-sdk";

import { InvitationStatusIconProps, StatusIcon } from "shared/components";
import { inviteStatusText } from "shared/lib";

import { Tooltip } from "@/modules/common/overlays/Tooltip";

export const InvitationStatusIcon = ({
  status,
  size,
}: InvitationStatusIconProps) => {
  // We don't want to show an icon if we haven't been invited
  if (status === InvitationStatus.NotInvited) {
    return null;
  }

  const [type, tooltipText] = inviteStatusText(status);

  return (
    <Tooltip
      showContent={!!tooltipText}
      trigger={
        <button type="button" className="flex items-center">
          <StatusIcon type={type} size={size} />
        </button>
      }>
      {tooltipText}
    </Tooltip>
  );
};
