import { generateDateRange } from "../../../../dateUtils";
import { formatDateTime } from "../../../../intlFormatter";
import { useGetCompanyHolidaysBetweenDates } from "../../../../queries/useGetCompanyHolidaysBetweenDates";
import { useListMakeUpsForStudentsBetweenDates } from "../../../../queries/useListMakeUpsForStudentsBetweenDates";
import { useListTrialsForStudentsBetweenDates } from "../../../../queries/useListTrialsForStudentsBetweenDates";

export function useWidgetDataForStudents(studentIds: string[]) {
  // Get all lessons for the week
  const startDate = formatDateTime(new Date(), "isoDate");
  const dates = generateDateRange(startDate, 7);
  const endDate = dates[6];

  const { holidays, isLoading: holidaysLoading } =
    useGetCompanyHolidaysBetweenDates(startDate, endDate);

  const { trials, isLoading: trialsLoading } =
    useListTrialsForStudentsBetweenDates(studentIds, startDate, endDate);

  const { makeUps, isLoading: makeUpsLoading } =
    useListMakeUpsForStudentsBetweenDates(studentIds, startDate, endDate);

  const isLoading = holidaysLoading || trialsLoading || makeUpsLoading;

  return {
    startDate,
    endDate,
    dates,
    holidays,
    isLoading,
    trials,
    makeUps,
  };
}
