import { FormDefinitionBuilder } from "../../forms/formDefinitionBuilder";
import { moneyFloatToInteger } from "../../moneyUtils";
import { getPlatformFunctions } from "../../platformSpecific";
import { useCurrencySymbol } from "../useCurrencySymbol";

interface Schema {
  amount: number;
  description: string;
}

export const makeRefundForm = () => () => {
  const currencySymbol = useCurrencySymbol();

  return new FormDefinitionBuilder<Schema>()

    .decimal("amount", {
      label: "Amount",
      required: true,
      decimalPlaces: 2,
      prefix: currencySymbol,
    })
    .text("description", {
      label: "Description",
    })
    .group("Refund details", ["amount", "description"])
    .getDefinition();
};

export const makeRefundRequest = ({
  familyId,
  transactionDetailId,
}: {
  familyId: string;
  transactionDetailId: string;
}) => {
  const { api } = getPlatformFunctions();
  return (formData: Schema) => {
    return api.stripe.stripeCreateRefund({
      familyId,
      transactionDetailId,
      stripeCreateRefundRequest: {
        amount: moneyFloatToInteger(formData.amount),
        description: formData.description,
      },
    });
  };
};
