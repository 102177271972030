import {
  EnrolmentAdjustmentReason,
  EnrolmentAdjustmentType,
} from "@justraviga/classmanager-sdk";

import { getPlatformFunctions } from "../platformSpecific";

export function useListMakeUpsForStudentsBetweenDates(
  studentIds: string[],
  startDate: string,
  endDate: string,
) {
  const { useApi } = getPlatformFunctions();

  const { data: makeUps, isLoading } = useApi(
    "listEnrolmentAdjustment",
    {
      where: {
        studentId: {
          in: studentIds,
        },
        reason: {
          equals: EnrolmentAdjustmentReason.MakeUpLesson,
        },
        startAt: {
          lessThanOrEqual: endDate,
        },
        endAt: {
          greaterThanOrEqual: startDate,
        },
        type: {
          equals: EnrolmentAdjustmentType.Addition,
        },
      },
      selectAll: true,
    },
    {
      enabled: studentIds.length > 0,
    },
  );

  return { makeUps: makeUps?.data ?? [], isLoading };
}
