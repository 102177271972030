import { InvitationStatus } from "@justraviga/classmanager-sdk";

import { IconName } from "../../../../availableIcons";
import { colors } from "../../../../colors";
import { useGenericComponents } from "../../../GenericComponentsProvider";

type StatusIconSize = "sm" | "md" | "lg";

export type StatusIconType = "default" | "invited" | "success";

export interface InvitationStatusIconProps {
  status: InvitationStatus;
  size?: StatusIconSize;
}

const iconMap: Record<StatusIconType, IconName> = {
  default: "checkmarkCircle",
  invited: "time",
  success: "checkmarkCircle",
};

const iconColorMap: Record<StatusIconType, string> = {
  default: colors.grey[500],
  invited: colors.grey[900],
  success: colors.green[600],
};

function getIconSize(size: StatusIconSize) {
  switch (size) {
    case "md":
      return 24;
    case "lg":
      return 28;
    case "sm":
    default:
      return 16;
  }
}

interface StatusIconProps {
  type: StatusIconType;
  size?: StatusIconSize;
}

export const StatusIcon = ({ type, size = "sm" }: StatusIconProps) => {
  const { Icon } = useGenericComponents();

  return (
    <Icon
      name={iconMap[type]}
      color={iconColorMap[type]}
      size={getIconSize(size)}
    />
  );
};
