import { InvitationStatus } from "@justraviga/classmanager-sdk";

import { StatusIconType } from "../../../components/modules/common/invitation/StatusIcon";

export function inviteStatusText(
  invitationStatus: InvitationStatus,
): [StatusIconType, string] {
  switch (invitationStatus) {
    case "invited":
      return ["invited", "Class Manager invitation pending"];
    case "accepted":
      return ["success", "Active Class Manager member"];
    default:
      return ["default", "Not invited"];
  }
}
