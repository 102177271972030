import { ReactNode } from "react";

import { ClassColor } from "@justraviga/classmanager-sdk";

import { IconName } from "../availableIcons";

/**
 * These are designed to be the props that can be passed to form components
 * as part of the form definition. They potentially represent a smaller subset
 * of the actual props that can be passed to the components, but these are the
 * ones we want to use consistently across both web and mobile platforms.
 */

export type BaseInputValue = string | null;

export interface BaseInputProps {
  append?: ReactNode;
  borderRadius?: "rounded" | "rounded-full";
  className?: string;
  description?: ReactNode;
  disabled?: boolean;
  error?: boolean;
  height?: "xs" | "sm" | "md";
  label?: string;
  numberOfLines?: number;
  onBlur?: () => void;
  onChange?: (value: BaseInputValue) => void;
  placeholder?: string;
  prefix?: string;
  prepend?: ReactNode;
  required?: boolean;
  selectTextOnFocus?: boolean;
  type?: "text" | "password" | "numeric" | "decimal" | "tel" | "email" | "url";
  value?: BaseInputValue;
}

export interface TextInputProps
  extends Omit<BaseInputProps, "append" | "prepend"> {
  appendIcon?: IconName;
  prependIcon?: IconName;
  numberOfLines?: number;
}

export interface RichTextInputProps {
  description?: string;
  disabled?: boolean;
  error?: boolean;
  label?: string;
  name?: string;
  numberOfLines?: number;
  onBlur?: () => void;
  onChange?: (value: string) => void;
  required?: boolean;
  value?: string;
}

export interface PasswordInputProps
  extends Omit<BaseInputProps, "append" | "prepend" | "type"> {}

export interface ImagePickerProps {
  aspectRatio?: [number, number];
  description?: string;
  error?: boolean;
  label?: string;
  name?: string;
  onChange?: (value: ImagePickerValue) => void;
  required?: boolean;
  value?: ImagePickerValue;
}

export type ImagePickerValue = string | null;

export type IntegerInputValues = number | null;

export interface IntegerInputProps
  extends Omit<
    BaseInputProps,
    "append" | "prepend" | "placeholder" | "onChange" | "type"
  > {
  onChange?: (value: IntegerInputValues) => void;
  prependIcon?: IconName;
}

export type DecimalInputValue = number | null;

export interface DecimalInputProps
  extends Omit<
    BaseInputProps,
    "append" | "prepend" | "placeholder" | "onChange" | "type"
  > {
  decimalPlaces: number;
  onChange?: (value: DecimalInputValue) => void;
  prependIcon?: IconName;
}

export type CheckboxValue = boolean | "indeterminate";

export interface CheckboxWithTextProps {
  checked?: CheckboxValue;
  disabled?: boolean;
  onChange?: (v: CheckboxValue) => void;
  text: ReactNode;
  title?: string;
  required?: boolean;
}

export interface ColorPickerProps extends BaseInputProps {}

export interface ColorPaletteProps {
  name?: string;
  error?: boolean; // Although we don't do anything with this currently
  value?: ClassColor | null;
  onChange?: (ClassColor: string) => void;
  onBlur?: () => void;
  label?: string;
  description?: string;
  required?: boolean;
}

export const colorPaletteColors: Record<ClassColor, string> = {
  [ClassColor.Grey]: "#E2E4EE",
  [ClassColor.Red]: "#FDD2D2",
  [ClassColor.Orange]: "#FDE6D2",
  [ClassColor.Yellow]: "#FDFAD2",
  [ClassColor.Lime]: "#ECFDD2",
  [ClassColor.Green]: "#D2FDD4",
  [ClassColor.Turquoise]: "#D2FDEE",
  [ClassColor.Sky]: "#D2F7FD",
  [ClassColor.Blue]: "#D2E3FD",
  [ClassColor.Purple]: "#D5D2FD",
  [ClassColor.PinkPurple]: "#E9D2FD",
  [ClassColor.Pink]: "#FDD2FD",
};

export interface SwitchProps {
  checked?: boolean;
  description?: string;
  label: string;
  onChange?: (checked: boolean) => void;
  required?: boolean;
}

export type DatePickerValue = string | null;

export interface DatePickerProps {
  description?: string;
  label: string;
  locale?: string;
  onBlur?: () => void;
  onChange?: (date: DatePickerValue) => void;
  required?: boolean;
  minDate?: Date;
  maxDate?: Date;
}

export type TimePickerValue = string | null;

export interface TimePickerProps {
  description?: ReactNode;
  error?: boolean;
  label?: string;
  minutesStep?: 1 | 2 | 3 | 4 | 5 | 6 | 10 | 12 | 15 | 20 | 30;
  name?: string;
  onBlur?: () => void;
  onChange?: (time: TimePickerValue) => void;
  required?: boolean;
  locale: string;
  clockType: "12-hour" | "24-hour";
  value?: TimePickerValue;
}

export type DayMonthPickerValue = string | null;

export interface DayMonthPickerProps {
  description?: ReactNode;
  error?: boolean;
  label?: string;
  name?: string;
  onBlur?: () => void;
  onChange?: (time: DayMonthPickerValue) => void;
  required?: boolean;
  locale: string;
  value?: DayMonthPickerValue;
}

export interface BigRadioOption {
  value: string | number;
  icon: IconName;
  iconStyle?: "branded" | "default";
  iconColor?: string;
  label: string;
  description?: string;
  disabled?: boolean;
}

export interface BigRadioProps {
  options: BigRadioOption[];
  label?: string;
  value?: string | number | undefined;
  name?: string;
  onChange?: (value: string | number | undefined) => void;
  singleColOnly?: boolean;
  error?: boolean;
  required?: boolean;
}
