import React, { ReactElement } from "react";

import * as ReactMenubar from "@radix-ui/react-menubar";

import {
  ActionMenuContainer,
  ActionMenuItems,
  ActionMenuTitle,
} from "shared/components";
import { ActionMenuProps, cn, useBreakpoint } from "shared/lib";

import { Sheet } from "@/modules/common/overlays/dialog/Sheet";
import { ProtectedOverlay } from "@/modules/common/ui/ProtectedOverlay";

/**
 * @deprecated - use shared action menu instead
 */
export const ActionMenu = ({
  title,
  size = "md",
  trigger,
  items,
}: ActionMenuProps) => {
  const { md: isDesktop } = useBreakpoint();

  const [bottomSheetOpen, setBottomSheetOpen] = React.useState(false);
  if (!isDesktop) {
    return (
      <div>
        {React.cloneElement(trigger as ReactElement, {
          onClick: (event: Event) => {
            event.stopPropagation();
            setBottomSheetOpen(true);
          },
        })}
        <Sheet
          isModal={false}
          open={bottomSheetOpen}
          hasBackdropOnOpen={true}
          containerPadding={3}
          onInteractOutside={event => {
            event.preventDefault();
            event.stopPropagation();
          }}
          onOpenChange={(open: boolean) => {
            setBottomSheetOpen(open);
          }}
          onCloseIconClick={event => {
            event.preventDefault();
            event.stopPropagation();
            setBottomSheetOpen(false);
          }}
          zIndex={1}
          side={"bottom"}
          padding={"px-5 pt-5 pb-1"}
          title={<ActionMenuTitle title={title || ""} />}
          content={
            <ActionMenuContainer>
              <ActionMenuItems
                items={items}
                onActionPress={() => {
                  setBottomSheetOpen(false);
                }}
                ProtectedOverlay={ProtectedOverlay}
                ClickableWrapper={({ onClick, className, children }) => (
                  <div onClick={onClick} className={className}>
                    {children}
                  </div>
                )}
              />
            </ActionMenuContainer>
          }
        />
      </div>
    );
  }

  return (
    <ReactMenubar.Root>
      <ReactMenubar.Menu>
        <ReactMenubar.Trigger asChild onClick={e => e.stopPropagation()}>
          {trigger}
        </ReactMenubar.Trigger>
        <ReactMenubar.Portal>
          <ReactMenubar.Content
            className={cn("rounded-md border border-grey-900 shadow-md", {
              "w-56": size === "md",
              "w-80": size === "lg",
            })}
            align={"end"}
            sideOffset={10}
            alignOffset={1}>
            <ActionMenuContainer>
              {title && (
                <div className={"p-2"}>
                  <ActionMenuTitle title={title} />
                </div>
              )}
              <ActionMenuItems
                items={items}
                onActionPress={() => {}}
                ProtectedOverlay={ProtectedOverlay}
                ClickableWrapper={({
                  onClick,
                  className,
                  disabled,
                  children,
                }) => (
                  // react-menubar adds some extra styling that we don't want
                  <ReactMenubar.Item
                    className={cn(
                      "hover:rounded-sm hover:border-0 hover:bg-grey-100 focus-visible:outline-0",
                      className,
                    )}
                    onClick={onClick}
                    disabled={disabled}>
                    {children}
                  </ReactMenubar.Item>
                )}
              />
            </ActionMenuContainer>
          </ReactMenubar.Content>
        </ReactMenubar.Portal>
      </ReactMenubar.Menu>
    </ReactMenubar.Root>
  );
};
