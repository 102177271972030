import { FC } from "react";

import { ActivityLogDto } from "@justraviga/classmanager-sdk";

import { DatatableConfiguration, DatatableQueryProps } from "../../datatable";
import { formatDateTime } from "../../intlFormatter";
import { getPlatformFunctions } from "../../platformSpecific";
import { ActivityLogItem } from "../modules/company/dashboard/activityLog/ActivityLogItem";

type Dto = ActivityLogDto;

export const useActivityLogDatatable = ({
  Datatable,
  onRowClick,
}: {
  Datatable: FC<{ configuration: DatatableConfiguration<Dto, object, Dto> }>;
  onRowClick: (item: Dto) => void;
}) => {
  const { api } = getPlatformFunctions();

  const fetchData = async (query: DatatableQueryProps) => {
    return api.activityLog.listActivityLog(query.toRequest());
  };

  const renderLogItem = (item: Dto) => {
    return <ActivityLogItem log={item} isWidgetItem={false} />;
  };

  const config: DatatableConfiguration<Dto, object, Dto> = {
    id: "activityLog",
    title: "Activity Log",
    hasPagination: true,
    placeholdersCount: 5,
    hasSearch: false,
    fetchData,
    showTotalRecords: true,
    rowActions: {
      click: onRowClick,
    },
    columns: [
      {
        label: "Activity",
        placeholder: "tile",
        value: row => ({
          type: "custom",
          children: renderLogItem(row.item),
        }),
        width: "50%",
      },
      {
        label: "Date",
        placeholder: "text",
        value: row => ({
          type: "text",
          text: formatDateTime(row.item.createdAt, "dayMonthYear"),
        }),
      },
      //@TODO: Remove this once we fix the issue with Datatable
      {
        label: " ",
        placeholder: "text",
        value: () => ({
          type: "text",
          text: " ",
        }),
      },
    ],
    mobileColumn: {
      hasImage: false,
      children: row => renderLogItem(row.item),
    },
    contentPlaceholders: {
      noContent: {
        title: "Nothing to show here",
        icon: "helpCircleOutline",
      },
    },
  };

  return {
    datatable: <Datatable configuration={config} />,
  };
};
