import { getPlatformFunctions } from "../platformSpecific";

export function useListSeasonsById(seasonIds: string[]) {
  const { useApi } = getPlatformFunctions();

  const { data: seasons, isLoading } = useApi(
    "listSeason",
    { where: { id: { in: seasonIds } } },
    { enabled: seasonIds.length > 0 },
  );

  return { seasons: seasons?.data ?? [], isLoading };
}
