import { useEffect } from "react";

import { StudentDto } from "@justraviga/classmanager-sdk";

import { getFullName } from "../../personUtil";
import { getPlatformFunctions } from "../../platformSpecific";
import { useFormActions } from "../FormActionsProvider";
import {
  makeMakeUpLessonRequest,
  useMakeUpLessonForm,
} from "../formDefinitions/makeUpLessonForm";
import { useGenericComponents } from "../GenericComponentsProvider";

export const MakeUpLessonForm = ({
  student,
  onSuccess,
}: {
  student: StudentDto;
  onSuccess: (message: string) => void;
}) => {
  const { GenericForm } = useGenericComponents();
  const { api } = getPlatformFunctions();
  const { form, lessonDate } = useMakeUpLessonForm(student.id);
  const formActions = useFormActions();

  useEffect(() => {
    formActions.setAllowCreateAdditional(false);
    formActions.setCreateButtonText("Assign");
  }, [formActions]);

  return (
    <GenericForm
      apiRequest={makeMakeUpLessonRequest({
        api,
        studentId: student.id,
      })}
      defaultValues={{}}
      formDefinitionHook={() => form}
      onSuccess={async adjustment => {
        const course = await api.courses.getCourse({
          id: adjustment[0].classId,
        });

        onSuccess(
          `${getFullName(student)} was added to ${course.entity.name} on ${lessonDate} successfully.`,
        );
      }}
    />
  );
};
