import { match } from "ts-pattern";

export type IconButtonSize = "xs" | "sm" | "lg" | "2xl";

export const getIconSize = (size: IconButtonSize) => {
  switch (size) {
    case "xs":
      return 16;
    case "sm":
      return 20;
    case "lg":
      return 24;
    case "2xl":
      return 32;
  }
};

export const getIconButtonHitSlop = (size: IconButtonSize) => {
  switch (size) {
    case "xs":
      return 12;
    case "sm":
      return 8;
    case "lg":
      return 4;
    case "2xl":
      return 0;
  }
};

export const getStretchIconButtonSizeClasses = (size: IconButtonSize) => {
  return match(size)
    .with("xs", () => "h-4 w-4")
    .with("sm", () => "h-5 w-5")
    .with("lg", () => "h-6 w-6")
    .with("2xl", () => "h-8 w-8")
    .exhaustive();
};
