import { useGenericComponents } from "../GenericComponentsProvider";

export const ActionMenuHeader = ({ title }: { title: string }) => {
  const { Text, View } = useGenericComponents();
  return (
    <View className={"p-2"}>
      <Text className={"text-body-600 text-grey-900"} truncate>
        {title}
      </Text>
    </View>
  );
};
