import { getPlatformFunctions } from "../platformSpecific";

export function useListStudentsForFamily(familyId: string) {
  const { useApi } = getPlatformFunctions();

  const { data: students, isLoading } = useApi("listStudent", {
    where: {
      familyId: {
        equals: familyId,
      },
    },
    selectAll: true,
  });

  return { students: students?.data ?? [], isLoading };
}
