import { actionMenuOptions } from "shared/admin";
import { ActionMenu } from "shared/components";
import { ActionMenuProps } from "shared/lib";

import { CodeProps, DocWrapper } from "@/modules/admin/docs/DocWrapper";
import { Button } from "@/modules/common/ui/button/Button";
import { IconButton } from "@/modules/common/ui/button/IconButton";

export const ActionMenuPage = () => {
  const getTriggerCode = (
    props: ActionMenuProps[],
  ): CodeProps<ActionMenuProps>[] => {
    return props.map(function (prop: ActionMenuProps) {
      return {
        ...prop,
        trigger: "<Button text={'Options'} />",
      };
    });
  };
  return (
    <DocWrapper
      title={"Menubar"}
      component={ActionMenu}
      options={actionMenuOptions(Button, IconButton)}
      transformProps={getTriggerCode}
    />
  );
};
