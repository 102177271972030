import { StudentDto } from "@justraviga/classmanager-sdk";

import { Avatar, FamilyCoursePreview } from "shared/components";
import {
  cn,
  getClassSubtitle,
  PendingEnrolmentCardProps,
  useBreakpoint,
} from "shared/lib";

import { useSheet } from "@/modules/common/overlays/dialog/context/useSheet";
import {
  ActionMenu,
  ActionMenuItemProps,
} from "@/modules/common/ui/ActionMenu";
import { IconButton } from "@/modules/common/ui/button/IconButton";
import { BaseCard } from "@/modules/common/ui/card/BaseCard";
import { HorizontalSeparator } from "@/modules/common/ui/Separator";

export const PendingEnrolmentCard = ({
  student,
  course,
  season,
  rightSlot,
  rightActions,
}: PendingEnrolmentCardProps<ActionMenuItemProps>) => {
  const { md: isDesktop } = useBreakpoint();
  const seasonFullDetail = getClassSubtitle(course.entity, season, true);
  const { openSheet } = useSheet();

  const showPreview = () => {
    openSheet({
      title: "Class details",
      content: (
        <FamilyCoursePreview
          course={course}
          season={season}
          HorizontalSeparator={HorizontalSeparator}
        />
      ),
    });
  };

  const RightActions = () => {
    if (!rightActions) {
      return null;
    }

    return (
      <ActionMenu
        header={
          rightActions.header ? (
            <h6 className={"text-heading6-600"}>{rightActions.header}</h6>
          ) : undefined
        }
        showActionsAsBottomSheetOnMobile={!isDesktop}
        trigger={
          <IconButton
            variant="standard"
            icon={"ellipsisHorizontal"}
            size="sm"
          />
        }
        items={rightActions.items}
      />
    );
  };

  const MobileView = () => {
    return (
      <BaseCard
        onClick={showPreview}
        padding={2}
        bodySlot={
          <div
            className={cn("grid grid-cols-3", {
              "items-center": rightActions !== undefined,
              "-mt-2": rightActions !== undefined,
            })}>
            <p className={"col-span-2 truncate text-body-600 text-grey-900"}>
              {course.entity.name}
            </p>
            <div className={"flex justify-end"}>
              {rightSlot && rightSlot}
              <RightActions />
            </div>
          </div>
        }
        footerSlot={
          <div className={"flex flex-col space-y-1"}>
            <p className={"truncate text-label-400"}>{seasonFullDetail}</p>
            <div className={"flex flex-row items-center space-x-2"}>
              <StudentAvatar student={student} />
              <p className={"ml-2 text-label-400"}>{student.firstname}</p>
            </div>
          </div>
        }
      />
    );
  };

  return isDesktop ? (
    <BaseCard
      onClick={showPreview}
      padding={2}
      bodySlot={
        <div className={"grid grid-cols-2"}>
          <section className={"flex flex-row items-center justify-between"}>
            <div>
              <p className={"truncate text-body-600 font-semibold"}>
                {course.entity.name}
              </p>
              <p className={"mt-2 truncate text-label-400"}>
                {seasonFullDetail}
              </p>
            </div>
            <StudentAvatar student={student} />
          </section>
          <section className={"flex items-center justify-between"}>
            <p className={"ml-2 text-body-400"}>{student.firstname}</p>
            {rightSlot && rightSlot}
            <RightActions />
          </section>
        </div>
      }
    />
  ) : (
    <MobileView />
  );
};

const StudentAvatar = ({ student }: { student: StudentDto }) => {
  return (
    <Avatar
      src={student.profilePicture ?? undefined}
      person={student}
      size={"xs"}
    />
  );
};
