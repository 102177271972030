import { AccountPermissionSet, StaffDto } from "@justraviga/classmanager-sdk";

import { enumLabel, getInitials } from "shared/lib";

import {
  DetailProfileAvatarContainer,
  DetailProfileContactList,
  DetailProfileContainer,
} from "@/modules/company/common/DetailProfile";
import { AvatarWithUpload } from "@/modules/company/common/details/profile/AvatarWithUpload";
import { JoinedDate } from "@/modules/company/common/details/profile/JoinedDate";
import { InvitationStatusIcon } from "@/modules/company/common/invitation/InvitationStatusIcon";

const Permission = ({
  permission,
}: {
  permission: AccountPermissionSet | null;
}) => {
  return (
    <div>
      <span className={"text-body-400 text-grey-600"}>Permission Level </span>
      <span className={"text-body-400 capitalize text-grey-900"}>
        {enumLabel("accountPermissionSet", permission) ?? "-"}
      </span>
    </div>
  );
};

const JobTitle = ({ jobTitle }: { jobTitle: StaffDto["jobTitle"] }) => (
  <div>
    <span className={"text-body-400 text-grey-600"}>Job Title </span>
    <span className={"text-body-400 text-grey-900"}>{jobTitle ?? "-"}</span>
  </div>
);

export const Profile = ({
  staff,
  isDesktop,
  uploadAction,
}: {
  staff: StaffDto;
  isDesktop: boolean;
  uploadAction: () => void;
}) => {
  return (
    <DetailProfileContainer>
      <DetailProfileAvatarContainer>
        <AvatarWithUpload
          src={staff.profilePicture}
          name={getInitials(staff)}
          showUploadIcon={!staff.archivedAt}
          uploadAction={uploadAction}
        />
        <div>
          <div className={"flex flex-row items-center space-x-1"}>
            <p className={"line-clamp-1 text-heading5-600 text-grey-900"}>
              {staff.firstname} {staff.lastname}
            </p>
            <InvitationStatusIcon status={staff.invitationStatus} size={"md"} />
          </div>
          {isDesktop && <JobTitle jobTitle={staff.jobTitle} />}
        </div>
      </DetailProfileAvatarContainer>
      <DetailProfileContactList email={staff.email} phone={staff.phone} />
      {!isDesktop && <JobTitle jobTitle={staff.jobTitle} />}
      <Permission permission={staff.permissionSet} />
      <JoinedDate date={staff.joinedAt} />
    </DetailProfileContainer>
  );
};
