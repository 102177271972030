import { useEffect, useRef, useState } from "react";

import {
  AggregateClassDto,
  LessonDto,
  SeasonDto,
} from "@justraviga/classmanager-sdk";

import {
  createFamilyLessonInstances,
  SimplifiedMakeupInstance,
} from "./createFamilyLessonInstances";
import { FamilyLessonCard } from "./FamilyLessonCard";
import { uniqueValuesForKey } from "../../../collectionUtils";
import { cn } from "../../../cssUtils";
import { formatDate } from "../../../intlFormatter";
import { lessonIsCancelled } from "../../../lessonUtils";
import { getPlatformFunctions } from "../../../platformSpecific";
import { useListCoursesById } from "../../../queries/useListCoursesById";
import { useListSeasonsById } from "../../../queries/useListSeasonsById";
import { useListStudentsForFamily } from "../../../queries/useListStudentsForFamily";
import { useGenericComponents } from "../../GenericComponentsProvider";
import { useWidgetDataForStudents } from "../common/schedule-widget/useWidgetDataForStudents";
import { widgetHelpers } from "../common/schedule-widget/widgetHelpers";
import { HolidayCard } from "../company/students/HolidayCard";

type StudentLessonInstance = Pick<
  LessonDto,
  "classId" | "date" | "startTime"
> & {
  studentId: string;
  cancelled: boolean;
};

export const FamilyScheduleWidget = ({
  goToCourse,
  familyId,
}: {
  goToCourse: (course: AggregateClassDto, season: SeasonDto) => void;
  familyId: string;
}) => {
  const { BaseCard, Text, View } = useGenericComponents();

  const {
    holidays,
    trials,
    makeUps,
    seasons,
    students,
    studentLessons,
    courses,
    isLoading,
    dates,
  } = useWidgetData(familyId);

  if (isLoading) {
    // our pattern is to show nothing while loading
    return null;
  }

  const familyLessonInstances = createFamilyLessonInstances(
    studentLessons,
    trials,
    makeUps as SimplifiedMakeupInstance[],
  );

  const { getCourseById, getSeasonById, getHolidaysForDate } = widgetHelpers(
    courses,
    seasons,
    holidays,
  );

  return (
    <BaseCard
      collapsible={true}
      title={"Schedule"}
      bodySlot={
        <View className="">
          <Text className="text-heading4-600 font-semibold text-grey-900">
            Next 7 days
          </Text>
          <View className="flex flex-col">
            {dates.map((date, index) => {
              const holidaysForDay = getHolidaysForDate(date);

              const lessonsForDay = familyLessonInstances.filter(
                l => l.date === date,
              );
              return (
                <View
                  key={date}
                  className={cn(
                    "border-grey-300 flex flex-col space-y-3 py-5",
                    { "border-b": index !== dates.length - 1 },
                  )}>
                  <View>
                    <Text className="text-label-400 text-grey-600">
                      {formatDate(date, "weekdayDayMonthYear")}
                      {holidaysForDay.length > 0
                        ? " (no classes)"
                        : lessonsForDay.length > 0
                          ? ` (${lessonsForDay.length} ${lessonsForDay.length === 1 ? "class" : "classes"})`
                          : " (no classes)"}
                    </Text>
                  </View>

                  {holidaysForDay.length > 0 ? (
                    <View key={`${date}-holiday`}>
                      <HolidayCard name={holidaysForDay[0].name} />
                    </View>
                  ) : (
                    lessonsForDay.map(lesson => {
                      const course = getCourseById(lesson.classId)!;
                      const season = getSeasonById(course.entity.seasonId)!;

                      return (
                        <View
                          key={[
                            lesson.classId,
                            lesson.date,
                            lesson.time,
                            lesson.studentIds,
                          ].join("")}>
                          <FamilyLessonCard
                            goToCourse={goToCourse}
                            lesson={{
                              ...lesson,
                              students: students.filter(student =>
                                lesson.studentIds.includes(student.id),
                              ),
                            }}
                            course={course}
                            season={season}
                          />
                        </View>
                      );
                    })
                  )}
                </View>
              );
            })}
          </View>
        </View>
      }
    />
  );
};

function useWidgetData(familyId: string) {
  const { students, isLoading: studentsLoading } =
    useListStudentsForFamily(familyId);

  const studentIds = uniqueValuesForKey("id", students);

  const {
    startDate,
    endDate,
    dates,
    holidays,
    trials,
    makeUps,
    isLoading: isLoadingCompanyData,
  } = useWidgetDataForStudents(studentIds);

  const { studentLessons, isLoading: studentLessonsLoading } =
    useGetLessonsForStudents(studentIds, startDate, endDate);

  // these 2 sets should be mutually exclusive already
  const courseIds = uniqueValuesForKey("classId", studentLessons ?? []).concat(
    uniqueValuesForKey("classId", trials ?? []),
  );
  const { courses, isLoading: coursesLoading } = useListCoursesById(courseIds);

  // I don't think we have a helper to reach into nested properties
  const seasonIds = [...new Set(courses.map(course => course.entity.seasonId))];
  const { seasons, isLoading: seasonsLoading } = useListSeasonsById(seasonIds);

  const isLoading =
    isLoadingCompanyData ||
    studentsLoading ||
    coursesLoading ||
    seasonsLoading ||
    studentLessonsLoading;

  return {
    holidays,
    trials,
    makeUps,
    seasons,
    students,
    studentLessons,
    courses,
    isLoading,
    dates,
  };
}

function useGetLessonsForStudents(
  studentIds: string[],
  startDate: string,
  endDate: string,
) {
  const { api } = getPlatformFunctions();
  const isLoading = useRef(false);
  const [studentLessons, setStudentLessons] = useState<
    StudentLessonInstance[] | undefined
  >(undefined);

  useEffect(() => {
    if (isLoading.current || studentIds.length === 0) {
      return;
    }

    isLoading.current = true;

    // We need to fetch lessons for each student individually, as the backend doesn't allow us to fetch by multiple
    // student IDs - and also the response shape doesn't tell us which student they relate to anyway.
    Promise.all(
      studentIds.map(studentId =>
        api.lessons
          .listOnDateLesson({
            fromDate: startDate,
            toDate: endDate,
            where: {
              studentId: {
                equals: studentId,
              },
            },
          })
          .then(lessons => {
            return lessons.map(l => ({
              ...l,
              studentId,
              cancelled: lessonIsCancelled(l),
            }));
          }),
      ),
    ).then(lessonArrays => {
      // Add the lessons to studentLessons
      setStudentLessons(lessonArrays.flat());
    });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [studentIds.length, startDate, endDate]);

  if (studentLessons === undefined) {
    return {
      studentLessons: [],
      isLoading: true,
    };
  }

  return { studentLessons, isLoading: false };
}
