import { Datatable } from "web/modules/common/datatable/Datatable";
import {
  Breadcrumb,
  CompanyBreadCrumbs,
  CompanyContent,
  CompanyLayout,
} from "web/modules/company/CompanyLayout";

import { useActivityLogDatatable } from "shared/components";

import { Router } from "@/routing/router";

export const ActivityLogListPage = () => {
  const goToStudent = (id: string) => Router.push("StudentDetails", { id });

  const { datatable } = useActivityLogDatatable({
    Datatable,
    onRowClick: item => goToStudent(item.entityId),
  });

  const breadcrumbs: Breadcrumb[] = [
    {
      text: "Dashboard",
      onPress: () => Router.push("CompanyHome"),
    },
    {
      text: "Activity Log",
    },
  ];

  return (
    <CompanyLayout datatablePage>
      <CompanyBreadCrumbs crumbs={breadcrumbs} />
      <CompanyContent>{datatable}</CompanyContent>
    </CompanyLayout>
  );
};
