import { useGenericComponents } from "../GenericComponentsProvider";

export const ActionMenuTitle = ({ title }: { title: string }) => {
  const { Text } = useGenericComponents();
  return (
    <Text className={"text-heading6-600 text-grey-900"} truncate>
      {title}
    </Text>
  );
};
