import { cn, colors, getAccessibleColorClass, getInitials } from "shared/lib";

import { useGenericComponents } from "../GenericComponentsProvider";

export interface AvatarProps {
  src?: string | undefined | null;
  className?: string;
  alt?: string;
  size?: "xs" | "sm" | "md" | "lg" | "xl";
  checked?: boolean;
  loading?: boolean;
  id?: string;
  person?: { firstname: string | null; lastname: string | null } | null;
  bgColor?: string;
}

export const Avatar = ({
  src,
  className,
  alt,
  size = "md",
  checked = false,
  loading = false,
  person,
  bgColor = colors.brand["500"],
}: AvatarProps) => {
  const { View, Icon, Loader } = useGenericComponents();
  const sizeClasses = getSizeClasses(size);
  return (
    <View
      className={cn(
        "relative flex items-center justify-center shrink-0 overflow-hidden rounded-full border-[1px] border-overlay-white-heavy",
        sizeClasses,
        className,
      )}
      data-testid="avatar"
      style={{ backgroundColor: !src && person ? bgColor : "" }}>
      <View
        className={"relative flex h-full w-full items-center justify-center"}>
        {src ? (
          <AvatarImage src={src} alt={alt} />
        ) : (
          <AvatarFallback
            alt={alt}
            person={person}
            fallbackBackgroundColor={bgColor}
            size={size}
          />
        )}
        {checked && (
          <View
            className={
              "absolute bottom-0 left-0 right-0 top-0 flex items-center justify-center rounded-full bg-grey-900 opacity-60"
            }></View>
        )}
        {checked && (
          <View className={"absolute"}>
            <Icon
              name={"checkmarkSharp"}
              size={getCheckIconSize(size)}
              color={"white"}
            />
          </View>
        )}

        {loading && (
          <View
            className={
              "absolute bottom-0 left-0 right-0 top-0 flex items-center justify-center rounded-full bg-white opacity-60"
            }></View>
        )}
        {loading && (
          <View className={"absolute"}>
            <Loader />
          </View>
        )}
      </View>
    </View>
  );
};

const getSizeClasses = (size: "xs" | "sm" | "md" | "lg" | "xl") => {
  switch (size) {
    case "xs":
      return "w-6 h-6";
    case "sm":
      return "w-8 h-8";
    case "md":
      return "w-10 h-10";
    case "lg":
      return "w-12 h-12";
    case "xl":
      return "w-16 h-16";
  }
};

const getPlaceholderIconSize = (size: "xs" | "sm" | "md" | "lg" | "xl") => {
  switch (size) {
    case "xs":
      return 12;
    case "sm":
      return 16;
    case "md":
      return 20;
    case "lg":
      return 24;
    case "xl":
      return 32;
  }
};

const getCheckIconSize = (size: "xs" | "sm" | "md" | "lg" | "xl") => {
  switch (size) {
    case "xs":
      // TODO: this is a complete guess, change this when we have a better idea of what the check icon size should be
      return 8;
    case "sm":
      return 10;
    case "md":
      return 12;
    case "lg":
      return 16;
    case "xl":
      return 20;
  }
};

const getInitialFontSizeClass = (size: "xs" | "sm" | "md" | "lg" | "xl") => {
  switch (size) {
    case "xs":
      return "text-caption-600 font-semibold";
    case "sm":
      return "text-caption-600 font-semibold";
    case "md":
      return "text-label-600 font-semibold";
    case "lg":
      return "text-heading6-600 font-semibold";
    case "xl":
      return "text-heading4-600 font-semibold";
  }
};

const AvatarImage = ({ src, alt }: { src: string; alt?: string }) => {
  const { Image } = useGenericComponents();
  return (
    <Image
      source={src}
      alt={alt}
      className={`aspect-square h-full w-full rounded-full`}
    />
  );
};

interface AvatarFallbackProps {
  alt?: string;
  size?: AvatarProps["size"];
  person?: AvatarProps["person"];
  fallbackBackgroundColor?: string;
}

const AvatarFallback = ({
  alt,
  size = "md",
  person,
  fallbackBackgroundColor,
}: AvatarFallbackProps) => {
  const { View, Text, Icon } = useGenericComponents();
  return (
    <>
      {person || alt ? (
        <View
          data-testid="initial-fallback"
          className={
            "flex h-full w-full items-center justify-center rounded-full"
          }
          style={{ backgroundColor: fallbackBackgroundColor ?? "" }}>
          <Text
            className={cn(
              getInitialFontSizeClass(size),
              "leading-none",
              `text-${getAccessibleColorClass(fallbackBackgroundColor)}`,
            )}>
            {person ? getInitials(person) : alt}
          </Text>
        </View>
      ) : (
        <View
          data-testid="placeholder-fallback"
          className={
            "flex h-full w-full items-center justify-center rounded-full bg-grey-400"
          }>
          <Icon
            name={"person"}
            size={getPlaceholderIconSize(size)}
            color={"white"}
          />
        </View>
      )}
    </>
  );
};
