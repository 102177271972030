import { Option } from "./adminPageTypes";
import { showAlert } from "../../alertState";
import {
  ActionMenuProps,
  ButtonComponent,
  IconButtonComponent,
} from "../../components/interfaces";

export const actionMenuOptions = (
  Button: ButtonComponent,
  IconButton: IconButtonComponent,
): Option<ActionMenuProps>[] => [
  {
    title: "Default menubar",
    props: [
      {
        trigger: <Button text={"Options"} variant={"secondary"} />,
        items: [
          {
            type: "item",
            icon: "createOutline",
            text: "Edit",
            onClick: () => {
              showAlert({ content: "Edit clicked" });
            },
          },
          {
            type: "item",
            icon: "copyOutline",
            text: "Duplicate",
            onClick: () => {
              showAlert({ content: "Duplicate clicked" });
            },
          },
          {
            type: "separator",
          },
          {
            type: "item",
            icon: "trashOutline",
            text: "Delete",
            variant: "destructive",
            onClick: () => {
              showAlert({ content: "Delete clicked" });
            },
          },
        ],
      },
    ],
  },

  {
    title: "Menubar with descriptions a title a long title and a disabled item",
    props: [
      {
        trigger: (
          <Button text={"Options with descriptions"} variant={"secondary"} />
        ),
        title: "Actions with a really long title that should be truncated",
        items: [
          {
            type: "item",
            icon: "createOutline",
            text: "Edit with a name that should be truncated if it can as we don't want it to be too wide",
            description: "Edit the record",
            onClick: () => {
              showAlert({ content: "Edit clicked" });
            },
          },
          {
            type: "item",
            icon: "copyOutline",
            text: "Duplicate",
            description: "Copy the record",
            disabled: true,
            onClick: () => {
              showAlert({ content: "Duplicate clicked" });
            },
          },
          { type: "separator" },
          {
            type: "item",
            icon: "trashOutline",
            text: "Delete",
            description: "Delete the record",
            variant: "destructive",
            onClick: () => {
              showAlert({ content: "Delete clicked" });
            },
          },
        ],
      },
    ],
  },

  {
    title: "Menubar triggered by icon button",
    props: [
      {
        trigger: (
          <IconButton
            icon={"ellipsisHorizontal"}
            variant={"standard"}
            size={"sm"}
          />
        ),
        items: [
          {
            type: "item",
            text: "Archive",
            icon: "archiveOutline",
            variant: "destructive",
            onClick: () => {
              showAlert({ content: "Archive clicked" });
            },
          },
          {
            type: "item",
            text: "Reject",
            icon: "trashOutline",
            variant: "destructive",
            onClick: () => {
              showAlert({ content: "Reject clicked" });
            },
          },
        ],
      },
    ],
  },

  {
    title: "Menubar with grouped items and a hidden item",
    props: [
      {
        trigger: <Button text={"Grouped options"} variant={"secondary"} />,
        title: "Actions",
        items: [
          {
            type: "item",
            text: "Approve",
            icon: "checkmarkOutline",
            onClick: () => {
              showAlert({ content: "Approve clicked" });
            },
          },
          {
            type: "separator",
          },
          {
            type: "group-heading",
            text: "Schedule with a really long title that should be truncated",
          },
          {
            type: "item",
            text: "Add to schedule",
            description:
              "The item below should be a separator as the next item (Remove from schedule) is hidden",
            icon: "calendarClearOutline",
            onClick: () => {
              showAlert({ content: "Add to schedule clicked" });
            },
          },
          {
            type: "item",
            text: "Remove from schedule",
            icon: "calendarClearOutline",
            visible: false,
            onClick: () => {
              showAlert({ content: "Remove from schedule clicked" });
            },
          },
          {
            type: "separator",
          },
          {
            type: "item",
            text: "Reject",
            icon: "trashOutline",
            variant: "destructive",
            onClick: () => {
              showAlert({ content: "Reject clicked" });
            },
          },
          {
            type: "item",
            text: "Delete",
            icon: "trashOutline",
            variant: "destructive",
            onClick: () => {
              showAlert({ content: "Delete clicked" });
            },
          },
        ],
      },
    ],
  },
];
