import {
  EnrolmentAdjustmentDto,
  LessonAdjustmentReason,
  LessonDto,
  StudentDto,
  TrialDto,
} from "@justraviga/classmanager-sdk";

import { HolidayCard } from "./HolidayCard";
import { StudentLessonCard, StudentLessonInstance } from "./StudentLessonCard";
import { uniqueValuesForKey } from "../../../../collectionUtils";
import { cn } from "../../../../cssUtils";
import { formatDate } from "../../../../intlFormatter";
import { getPlatformFunctions } from "../../../../platformSpecific";
import { useListCoursesById } from "../../../../queries/useListCoursesById";
import { useListSeasonsById } from "../../../../queries/useListSeasonsById";
import { useGenericComponents } from "../../../GenericComponentsProvider";
import { useWidgetDataForStudents } from "../../common/schedule-widget/useWidgetDataForStudents";
import { widgetHelpers } from "../../common/schedule-widget/widgetHelpers";

interface StudentScheduleWidgetProps {
  goToCourse: (courseId: string) => void;
  student: StudentDto;
}

export const StudentScheduleWidget = ({
  goToCourse,
  student,
}: StudentScheduleWidgetProps) => {
  const { BaseCard, Text, View } = useGenericComponents();

  const {
    holidays,
    lessons,
    trials,
    makeUps,
    courses,
    seasons,
    isLoading,
    dates,
  } = useWidgetData(student.id);

  if (isLoading) {
    /**
     * Our pattern is that we don't show loading spinners - we'll eventually make sure there
     * is a single loading spinner at the root of the page that we show until all the data is
     * loaded and ready.
     */
    return null;
  }

  const { getCourseById, getSeasonById, getHolidaysForDate } = widgetHelpers(
    courses,
    seasons,
    holidays,
  );

  function getTrialById(classId: string, date: string, time: string) {
    return trials.find(
      t => t.classId === classId && t.trialAt === date && t.time === time,
    );
  }

  const allLessons = transformLessonData(makeUps, lessons, trials);

  return (
    <BaseCard
      collapsible={true}
      title={"Weekly schedule"}
      bodySlot={
        <View>
          <Text className="text-heading4-600 font-semibold text-grey-900">
            Next 7 days
          </Text>
          <View className="flex flex-col">
            {dates.map((date, index) => {
              const holidaysForDay = getHolidaysForDate(date);
              const lessonsForDay = allLessons.filter(l => l.date === date);

              return (
                <View
                  key={date}
                  className={cn(
                    "border-grey-300 flex flex-col space-y-3 py-5",
                    { "border-b": index !== dates.length - 1 },
                  )}>
                  <View>
                    <Text className="text-label-400 text-grey-600">
                      {formatDate(date, "weekdayDayMonthYear")}
                      {holidaysForDay.length > 0
                        ? " (no classes)"
                        : lessonsForDay.length > 0
                          ? ` (${lessonsForDay.length} ${lessonsForDay.length === 1 ? "class" : "classes"})`
                          : " (no classes)"}
                    </Text>
                  </View>

                  {holidaysForDay.length > 0 ? (
                    <View key={date}>
                      <HolidayCard name={holidaysForDay[0].name} />
                    </View>
                  ) : (
                    lessonsForDay.map(lesson => {
                      const course = getCourseById(lesson.classId)!;
                      const season = getSeasonById(course.entity.seasonId)!;

                      return (
                        <View
                          key={[lesson.classId, lesson.date, lesson.time].join(
                            "",
                          )}>
                          <StudentLessonCard
                            student={student}
                            goToCourse={goToCourse}
                            lesson={lesson}
                            course={course}
                            season={season}
                            trial={getTrialById(
                              lesson.classId,
                              lesson.date,
                              lesson.time,
                            )}
                          />
                        </View>
                      );
                    })
                  )}
                </View>
              );
            })}
          </View>
        </View>
      }
    />
  );
};

function useWidgetData(studentId: string) {
  const { useApi } = getPlatformFunctions();

  const {
    startDate,
    endDate,
    dates,
    holidays,
    trials,
    makeUps,
    isLoading: isLoadingCompanyData,
  } = useWidgetDataForStudents([studentId]);

  const { data: lessons, isLoading: lessonsLoading } = useApi(
    "listOnDateLesson",
    {
      fromDate: startDate,
      toDate: endDate,
      where: {
        studentId: {
          equals: studentId,
        },
      },
    },
  );

  // these 2 sets should be mutually exclusive already
  const courseIds = uniqueValuesForKey("classId", lessons ?? []).concat(
    uniqueValuesForKey("classId", trials ?? []),
  );
  const { courses, isLoading: coursesLoading } = useListCoursesById(courseIds);

  // I don't think we have a helper to reach into nested properties
  const seasonIds = [...new Set(courses.map(course => course.entity.seasonId))];
  const { seasons, isLoading: seasonsLoading } = useListSeasonsById(seasonIds);

  const isLoading =
    isLoadingCompanyData || lessonsLoading || coursesLoading || seasonsLoading;

  return {
    holidays,
    lessons: lessons ?? [],
    trials,
    makeUps,
    courses,
    seasons,
    isLoading,
    dates,
  };
}

function transformLessonData(
  makeUps: EnrolmentAdjustmentDto[],
  lessons: LessonDto[],
  trials: TrialDto[],
) {
  const lessonType = (l: LessonDto) =>
    makeUps.some(
      m =>
        m.classId === l.classId &&
        m.startAt === l.date &&
        m.time === l.startTime,
    )
      ? "makeUp"
      : "lesson";

  const lessonsSimplified: Array<StudentLessonInstance> = lessons.map(l => ({
    classId: l.classId,
    date: l.date,
    time: l.startTime,
    type: lessonType(l),
    cancelled: l.adjustments.some(a =>
      [
        LessonAdjustmentReason.CancelledClass,
        LessonAdjustmentReason.CancelledLesson,
      ].includes(a.reason),
    ),
  }));

  const trialsSimplified: Array<StudentLessonInstance> = trials.map(t => ({
    classId: t.classId,
    date: t.trialAt,
    time: t.time,
    type: "trial",
    cancelled: false, // todo - we don't have a way of knowing this yet
  }));

  return [...lessonsSimplified, ...trialsSimplified];
}
