import {
  AggregateClassDto,
  PublicStaffDto,
} from "@justraviga/classmanager-sdk";

import { getPlatformFunctions } from "../platformSpecific";

export const useFamilyCoursePreview = (course: AggregateClassDto) => {
  const { useApi } = getPlatformFunctions();

  const roomId = course.entity.roomId ?? undefined;

  const { data: room } = useApi(
    "getRoom",
    { id: roomId! },
    { enabled: roomId !== undefined },
  );

  const { data: location } = useApi(
    "getLocation",
    { id: room?.locationId ?? "" },
    { enabled: room?.locationId !== undefined },
  );

  const { data: staff } = useApi(
    "getStaff",
    { id: course?.entity.staffId as string },
    { enabled: !!course && course.entity.staffId !== null },
  );

  return {
    room: room!,
    location: location!,
    staff: staff as PublicStaffDto,
  };
};
