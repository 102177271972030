import {
  EnrolmentAdjustmentDto,
  LessonDto,
  TrialDto,
} from "@justraviga/classmanager-sdk";

type StudentLessonInstance = Pick<
  LessonDto,
  "classId" | "date" | "startTime"
> & {
  studentId: string;
  cancelled: boolean;
};

type SimplifiedTrialInstance = Pick<
  TrialDto,
  "studentId" | "classId" | "trialAt" | "time"
>;

export type SimplifiedMakeupInstance = Pick<
  EnrolmentAdjustmentDto,
  "studentId" | "classId" | "startAt"
> & {
  // The DTO has an optional time, but we're expecting it to always have one, if it's a make-up
  time: string;
};

interface FamilyLessonInstance {
  studentIds: Array<string>;
  classId: string;
  date: string;
  time: string;
  cancelled: boolean;
  type: "lesson" | "trial" | "makeUp";
}

/**
 * The purpose of this function is to supply the FamilyScheduleWidget with a list of lessons that make sense to
 * that family. This means that where multiple students in the same family are taking the same lesson, we combine
 * them into a single instance. However, where the nature of the lesson for students differs, e.g. where one is
 * taking it as a trial, and the other is already enrolled, we shouldn't combine them. This also applies where
 * the lesson is a make-up lesson for one of the students, but not for the others.
 */
export const createFamilyLessonInstances = (
  lessons: Array<StudentLessonInstance>,
  trials: Array<SimplifiedTrialInstance>,
  makeUps: Array<SimplifiedMakeupInstance>,
): Array<FamilyLessonInstance> => {
  // First of all, we build our array of FamilyLessonInstance, then group it at the end
  const ungroupedLessons = [
    ...lessons.map(l => ({
      studentIds: [l.studentId],
      classId: l.classId,
      date: l.date,
      time: l.startTime,
      cancelled: l.cancelled,
      type: "lesson" as const,
    })),
    ...trials.map(t => ({
      studentIds: [t.studentId],
      classId: t.classId,
      date: t.trialAt,
      time: t.time,
      cancelled: false,
      type: "trial" as const,
    })),
  ];

  const lessonIsMakeUp = (l: FamilyLessonInstance) => {
    return makeUps.some(
      m =>
        lessonMatches(l, {
          ...m,
          date: m.startAt,
        }) && l.studentIds.includes(m.studentId),
    );
  };

  const groupedLessons = ungroupedLessons.reduce((acc, curr) => {
    const type =
      curr.type === "trial"
        ? "trial"
        : lessonIsMakeUp(curr)
          ? "makeUp"
          : "lesson";
    const existing = acc.find(a => lessonMatches(a, curr) && a.type === type);
    if (existing) {
      existing.studentIds.push(...curr.studentIds);
    } else {
      acc.push({
        ...curr,
        studentIds: [...curr.studentIds],
        type: type,
      });
    }
    return acc;
  }, [] as Array<FamilyLessonInstance>);

  // Sort lessons by date and time ascending
  return groupedLessons.sort((a, b) =>
    a.date === b.date
      ? a.time.localeCompare(b.time)
      : a.date.localeCompare(b.date),
  );
};

type LessonInstance = Pick<FamilyLessonInstance, "classId" | "date" | "time">;
const lessonMatches = (a: LessonInstance, b: LessonInstance) =>
  a.classId === b.classId && a.date === b.date && a.time === b.time;
