import { AccountPermission } from "@justraviga/classmanager-sdk";

import { useFamilyDatatable } from "shared/components";

import { api } from "@/lib/api/apiClient";
import { AvatarListCell } from "@/modules/common/datatable/cell/AvatarListCell";
import { Datatable } from "@/modules/common/datatable/Datatable";
import { InvitationStatusIcon } from "@/modules/company/common/invitation/InvitationStatusIcon";
import { CompanyContent, CompanyLayout } from "@/modules/company/CompanyLayout";
import { useFamilyActions } from "@/modules/company/members/family/useFamilyActions";
import { Router } from "@/routing/router";

export const FamilyListPage = () => {
  return (
    <CompanyLayout datatablePage permission={AccountPermission.Familylist}>
      <FamilyListContent />
    </CompanyLayout>
  );
};

const FamilyListContent = () => {
  const familyActions = useFamilyActions();

  const { datatable } = useFamilyDatatable({
    Datatable,
    api,
    archive: familyActions.archive,
    archiveMany: familyActions.archiveMany,
    avatarListCell: students => <AvatarListCell list={students} />,
    deleteMany: familyActions.deleteMany,
    deleteOne: familyActions.deleteOne,
    invitationStatus: item => (
      <InvitationStatusIcon status={item.invitationStatus} />
    ),
    onRowClick: item => Router.push("FamilyDetails", { id: item.id }),
    showCreateForm: familyActions.showCreateForm,
    showInviteForm: familyActions.showInviteForm,
    showUpdateForm: family => {
      return familyActions.showUpdateForm(family, {
        primaryContact: family.primaryContact,
      });
    },
    unarchive: familyActions.unarchive,
  });
  return <CompanyContent>{datatable}</CompanyContent>;
};
