import { ActivityLogDto, ActivityLogType } from "@justraviga/classmanager-sdk";
import { match } from "ts-pattern";

import { IconName } from "../../../../../availableIcons";
import { useBreakpoint } from "../../../../../breakpoints";
import { colors } from "../../../../../colors";
import { cn } from "../../../../../cssUtils";
import { formatDateTime } from "../../../../../intlFormatter";
import { getPlatformFunctions } from "../../../../../platformSpecific";
import { useGenericComponents } from "../../../../GenericComponentsProvider";

export const ActivityLogItem = ({
  log,
  isWidgetItem = true,
}: {
  log: ActivityLogDto;
  isWidgetItem?: boolean;
}) => {
  const { md: isDesktop } = useBreakpoint();
  const { View, Icon, Text } = useGenericComponents();
  const { renderMarkdown } = getPlatformFunctions();

  return (
    <View
      className={cn(
        "flex flex-row justify-between space-x-3 hover:bg-grey-100 cursor-pointer",
        {
          "py-2 px-2": isWidgetItem,
          "items-center": !isWidgetItem && isDesktop,
        },
      )}>
      <View>
        <View
          className={
            "flex h-10 w-10 items-center justify-center rounded-full bg-brand-100"
          }>
          <Icon size={24} name={iconName(log)} color={colors.brand[800]} />
        </View>
      </View>
      <View className={"flex-initial flex-grow"}>
        <Text
          className={cn({
            "text-label-400": isWidgetItem,
            "text-body-400": !isWidgetItem,
          })}>
          {renderMarkdown(log.message)}
        </Text>
      </View>
      {(!isDesktop || isWidgetItem) && (
        <View className={"flex-shrink-0"}>
          <Text className={"flex-1 text-label-400 text-grey-600"}>
            {formatDateTime(
              log.createdAt,
              isWidgetItem ? "dayMonth" : "dayMonthYear",
            )}
          </Text>
        </View>
      )}
    </View>
  );
};

function iconName(item: ActivityLogDto): IconName {
  return match<ActivityLogType, IconName>(item.activityType)
    .with(ActivityLogType.Enrolment, () => "calendar")
    .with(ActivityLogType.Trial, () => "time")
    .with(ActivityLogType.WaitingList, () => "reorderFour")
    .exhaustive();
}
