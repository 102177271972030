import * as React from "react";

import { useSearch, useSearchDialog } from "shared/lib";

import { SearchInput } from "@/modules/common/form/SearchInput";
import { IconButton } from "@/modules/common/ui/button/IconButton";

export const GlobalSearchInput: React.FC = () => {
  const { handleOpenChange } = useSearchDialog();
  const { search } = useSearch();

  return (
    <div
      className={`align-center flex w-full items-center justify-between border-b border-grey-400 px-5 py-4`}>
      <div className="grow">
        <SearchInput
          placeholder={"Search"}
          className={"border-0 px-0 py-0"}
          height={"xs"}
          onChange={value => search(value)}
          clearBtn="text"
        />
      </div>

      <div className="-mr-2">
        <IconButton
          onClick={() => handleOpenChange(false)}
          data-testid="close-btn"
          variant="standard"
          icon="closeOutline"
          size="sm"
          aria-label="Clear search input"
        />
      </div>
    </div>
  );
};
