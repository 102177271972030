import React, { PropsWithChildren, ReactNode } from "react";

import { CodeBlock } from "react-code-blocks";

import { generateComponentString, getComponentName } from "shared/lib";

import {
  AdminContent,
  AdminLayout,
  AdminTitleBar,
  AdminTitleBarTitle,
} from "@/modules/admin/AdminLayout";
import { DocHeader } from "@/modules/admin/docs/components/DocHeader";
import {
  ToggleContent,
  ToggleDoc,
} from "@/modules/admin/docs/components/ToggleDoc";

// todo: remove this in favour of interface in src/admin/docs/adminPageTypes
export interface Option<T = Array<object>> {
  title: string;
  description?: string;
  props: T[];
  children?: React.ReactNode;
}

interface DocWrapper<T> {
  title: string;
  intro?: ReactNode;
  component: React.ComponentType<T>;
  options: Array<Option<T>>;
  importPath?: string;
  transformProps?: (props: T[]) => CodeProps<T>[];
}

export type CodeProps<T> = {
  [K in keyof T]: T[K] | string;
};

const ComponentSection = <T,>({
  option,
  importPath,
  component,
  index,
  transformProps,
}: {
  index: number;
  option: Option<T>;
  importPath?: string;
  component: React.ComponentType<T>;
  transformProps: DocWrapper<T>["transformProps"];
}) => {
  const Component: React.ComponentType<T> = component;

  const generateImportPath = (component: React.ComponentType) => {
    const fullPath =
      importPath ||
      `{ ${getComponentName(component)} } from "@/modules/common/${
        component.displayName
      }";`;

    return `import ${fullPath}\n\n\n`;
  };

  const prepareCodeText = (
    component: React.ComponentType,
    props: CodeProps<T>[],
    children: React.ReactNode,
  ) => {
    let codeText = "";

    {
      props.map(function (prop: CodeProps<T>) {
        codeText +=
          generateComponentString(
            getComponentName(component as React.ComponentType),
            prop,
            children,
          ) + "\n\n";
      });
    }

    return (
      generateImportPath(component as React.ComponentType) + codeText
    ).trim();
  };

  return (
    <ToggleDoc>
      <DocHeader title={option.title} description={option.description} />
      <div className="flex flex-wrap justify-between gap-5">
        {option.props.map((props: T, buttonIndex) => {
          return (
            <Component {...props} key={`${index}-${buttonIndex}`}>
              {option.children && option.children}
            </Component>
          );
        })}
      </div>
      <ToggleContent>
        <CodeBlock
          as={undefined}
          forwardedAs={undefined}
          codeContainerStyle={{
            borderRadius: "0.5rem",
            padding: "1.2rem",
            fontFamily: "monospace",
            fontSize: "14px",
          }}
          text={prepareCodeText(
            component as React.ComponentType,
            transformProps ? transformProps(option.props) : option.props,
            option.children,
          )}
          showLineNumbers={false}
          language={"typescript"}
          wrapLongLines={true}
        />
      </ToggleContent>
    </ToggleDoc>
  );
};

export const DocIntro = ({ children }: PropsWithChildren) => (
  <div className="my-5">{children}</div>
);

export const DocWrapper = <T,>({
  title,
  intro,
  component,
  options,
  importPath,
  transformProps,
}: DocWrapper<T>) => {
  return (
    <AdminLayout>
      <AdminTitleBar>
        <AdminTitleBarTitle title={title} />
      </AdminTitleBar>
      <AdminContent>
        {intro && <DocIntro>{intro}</DocIntro>}
        {options.map((option: Option<T>, index) => (
          <ComponentSection
            index={index}
            component={component}
            importPath={importPath}
            option={option}
            key={index}
            transformProps={transformProps}
          />
        ))}
      </AdminContent>
    </AdminLayout>
  );
};
