import {
  AggregateClassDto,
  HolidayDto,
  SeasonDto,
} from "@justraviga/classmanager-sdk";

export function widgetHelpers(
  courses: AggregateClassDto[],
  seasons: SeasonDto[],
  holidays: HolidayDto[],
) {
  return {
    getCourseById(courseId: string) {
      return courses.find(course => course.entity.id === courseId);
    },
    getSeasonById(seasonId: string) {
      return seasons.find(season => season.id === seasonId);
    },
    getHolidaysForDate(date: string) {
      return holidays.filter(h => h.startAt <= date && h.endAt >= date);
    },
  };
}
