import { StudentDto } from "@justraviga/classmanager-sdk";

import { getAgeInMonths, getAgeInYears } from "./dateUtils";
import { formatDate } from "./intlFormatter";
import { enumLabel } from "./translateUtils";

export type StudentExtraInfoProps = Pick<
  StudentDto,
  "dateOfBirth" | "gender" | "joinedAt"
>;

type DisplayGenderProps = Pick<StudentDto, "gender">;

export const displayStudentGender = ({ gender }: DisplayGenderProps) => {
  return `Gender: ${gender ? enumLabel("studentGender", gender) : "-"}`;
};

type DisplayAgeProps = Pick<StudentDto, "dateOfBirth">;

export const displayStudentAge = (person: DisplayAgeProps, prefix = "Age ") => {
  if (!person.dateOfBirth) {
    return `${prefix}-`;
  }

  const ageInYears = getAgeInYears(person.dateOfBirth);
  if (ageInYears >= 2) {
    return `${prefix}${ageInYears} yrs`;
  }

  const ageInMonths = getAgeInMonths(person.dateOfBirth);
  if (ageInYears === 1) {
    const monthsMinusYear = ageInMonths - 12;
    return `${prefix}1 yr ${monthsMinusYear} mo`;
  }

  return `${prefix}${ageInMonths} mo`;
};

export const displayStudentDateOfBirth = ({ dateOfBirth }: DisplayAgeProps) => {
  return `Date of birth: ${dateOfBirth ? formatDate(dateOfBirth, "dayMonthYear") : "-"}`;
};

export const displayStudentNames = (
  students: Array<Pick<StudentDto, "firstname">>,
) => {
  const names = students.map(student => student.firstname);

  const otherStudents = names.sort();
  const lastStudent = otherStudents.pop();

  return [otherStudents.join(", "), lastStudent]
    .filter(name => Boolean(name))
    .join(" and ");
};

export const mobileStudentDetailSubtitle = (person: DisplayAgeProps) => {
  const ageDisplay = displayStudentAge(person);
  if (!person.dateOfBirth) {
    return displayStudentAge(person);
  }

  const dob = formatDate(person.dateOfBirth, "dayMonthYear");

  return `${ageDisplay} · ${dob}`;
};

export function studentAge(student: StudentDto, onDate: string) {
  const age = getAgeInYears(student.dateOfBirth!, onDate);
  return `${age} year${age > 1 ? "s" : ""}`;
}
