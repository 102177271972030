import { colors } from "../../../../colors";
import { useGenericComponents } from "../../../GenericComponentsProvider";

export const HolidayCard = ({ name }: { name: string }) => {
  const { Icon, Text, View } = useGenericComponents();
  return (
    <View className="bg-grey-200 py-2 px-3 rounded flex flex-row gap-x-2 items-center">
      <View>
        <Icon name="airplaneOutline" size={24} color={colors.grey[900]} />
      </View>
      <View>
        <Text className="text-label-400 text-grey-900">Closed ({name})</Text>
      </View>
    </View>
  );
};
