import React from "react";

import { EnrolmentDto, StudentDto } from "@justraviga/classmanager-sdk";

import { displayLessonTimes } from "../../../../classUtils";
import { ClassEntity } from "../../../../entities";
import { displayStudentNames } from "../../../../studentUtils";
import { useGenericComponents } from "../../../GenericComponentsProvider";
import { AvatarGroup } from "../../../ui/AvatarGroup";

interface CourseItemProps {
  item: { entity: ClassEntity; enrollments: EnrolmentDto[] };
  courseItem: (
    body: React.ReactNode,
    footer: React.ReactNode,
  ) => React.ReactNode;
  familyMembers: StudentDto[];
}
export const CourseItem = ({
  item,
  courseItem,
  familyMembers,
}: CourseItemProps) => {
  const { Text, View } = useGenericComponents();
  const { class: course } = item.entity;
  const students: StudentDto[] = item.enrollments.map(
    enrollment =>
      familyMembers.find(member => member.id === enrollment.studentId)!,
  );

  return (
    <View key={item.entity.class.id} className={"mt-3"}>
      {courseItem(
        <View className={"flex flex-row space-x-4"}>
          <View className={"min-w-0 flex-1"}>
            <Text className={"text-body-600 text-grey-900"} truncate={true}>
              {course.name}
            </Text>
          </View>
          <View className={"flex-initial"}>
            <AvatarGroup list={students} size={"xs"} />
          </View>
        </View>,
        <View className="flex flex-row items-center justify-between space-x-4 pt-1">
          <View className={"flex-initial"}>
            <Text className="whitespace-nowrap">
              {displayLessonTimes(course)}
            </Text>
          </View>
          <View className={"min-w-0 flex-1"}>
            <Text
              className="text-right text-label-400 leading-relaxed text-grey-900"
              truncate={true}>
              {displayStudentNames(students)}
            </Text>
          </View>
        </View>,
      )}
    </View>
  );
};
