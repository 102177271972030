import { getPlatformFunctions } from "../platformSpecific";

export function useListTrialsForStudentsBetweenDates(
  studentIds: string[],
  startDate: string,
  endDate: string,
) {
  const { useApi } = getPlatformFunctions();

  const { data: trials, isLoading } = useApi(
    "listTrial",
    {
      where: {
        studentId: {
          in: studentIds,
        },
        trialAt: {
          greaterThanOrEqual: startDate,
          lessThanOrEqual: endDate,
        },
      },
      selectAll: true,
    },
    {
      enabled: studentIds.length > 0,
    },
  );

  return { trials: trials?.data ?? [], isLoading };
}
