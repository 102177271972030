import { useEffect, useRef } from "react";

import { SortSchema } from "@justraviga/classmanager-sdk";

import { getPlatformFunctions } from "../platformSpecific";
import {
  calculateBalances,
  getTransactionPrimaryType,
} from "../transactionUtils";

type Balances = Record<string, number>;

interface PromiseObject {
  promise: Promise<Balances>;
  resolve: (balances: Balances) => void;
  reject: () => void;
}

export const useFamilyTransactionsData = (
  familyId: string | undefined | null,
) => {
  // Some areas of the app, like datatables, need balances in a non-reactive way (i.e. can be awaited)
  const promiseRef = useRef<PromiseObject | null>(null);
  if (!promiseRef.current) {
    promiseRef.current = {} as PromiseObject;
    promiseRef.current.promise = new Promise<Balances>((resolve, reject) => {
      promiseRef.current!.resolve = resolve;
      promiseRef.current!.reject = reject;
    });
  }

  const { useApi } = getPlatformFunctions();

  const { data: balanceDto } = useApi(
    "getBalance",
    {
      familyId: familyId!,
    },
    { enabled: !!familyId },
  );

  const { data: transactionResponse } = useApi(
    "listTransaction",
    {
      where: { familyId: { equals: familyId! } },
      selectAll: true,
      sort: {
        date: SortSchema.Desc,
        createdAt: SortSchema.Desc,
      },
    },
    { enabled: !!familyId },
  );

  const balance = balanceDto ? balanceDto.balance : 0;
  const transactions = (
    !transactionResponse ? [] : transactionResponse.data
  ).map(t => ({
    ...t,
    primaryType: getTransactionPrimaryType(t),
  }));

  // Get the running balance for all transactions, in the same order
  const balances = calculateBalances(
    balance,
    transactions.map(t => [
      t.transaction.type,
      t.details.map(d => d.details.amount),
    ]),
  );

  // Build a map of transaction ID to balance
  const balancesObject: Balances = transactions.reduce(
    (acc, transaction, i) => {
      acc[transaction.aggregateId] = balances[i];
      return acc;
    },
    {} as Balances,
  );

  // Once we've loaded all the transactions, resolve the promise, for code that has awaited it
  useEffect(() => {
    if (promiseRef?.current && balanceDto && transactionResponse?.data) {
      promiseRef.current.resolve(balancesObject);
    }
  }, [balanceDto, balancesObject, transactionResponse]);

  return {
    balance,
    balances: balancesObject,
    balancesPromise: promiseRef.current.promise,
    isLoading: !balanceDto || !transactionResponse,
    transactions,
  };
};
