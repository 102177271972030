import { getPlatformFunctions } from "../platformSpecific";

export function useListCoursesById(courseIds: string[]) {
  const { useApi } = getPlatformFunctions();

  const { data: courses, isLoading } = useApi(
    "listCourse",
    { where: { id: { in: courseIds } } },
    { enabled: courseIds.length > 0 },
  );

  return { courses: courses?.data ?? [], isLoading };
}
