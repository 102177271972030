import { getPlatformFunctions } from "../platformSpecific";

export function useGetCompanyHolidaysBetweenDates(
  startDate: string,
  endDate: string,
) {
  const { useApi } = getPlatformFunctions();

  const { data, isLoading } = useApi("listHoliday", {
    where: {
      startAt: {
        lessThanOrEqual: endDate,
      },
      endAt: {
        greaterThanOrEqual: startDate,
      },
    },
    selectAll: true,
  });

  return { holidays: data?.data ?? [], isLoading };
}
