import {
  BalanceIndicator,
  ContentPlaceholder,
  TransactionSummaryCell,
  useAuthState,
} from "shared/components";
import { useFamilyTransactionsData } from "shared/lib";

import { usePaymentMethods } from "@/modules/common/payments/usePaymentMethodActions";
import { LoadingPlaceholder } from "@/modules/common/search/LoadingPlaceholder";
import { Button } from "@/modules/common/ui/button/Button";
import { BaseCard } from "@/modules/common/ui/card/BaseCard";
import { useTransactionActions } from "@/modules/company/billing/transactions/useTransactionActions";
import { SummaryCard } from "@/modules/company/classPlanner/classes/classDetails/SummaryCard";
import { CustomerLayout } from "@/modules/customer/CustomerLayout";
import { Router } from "@/routing/router";

export const CustomerBillingPage = () => {
  const { account } = useAuthState();

  const { isLoading, balance, balances, transactions } =
    useFamilyTransactionsData(account?.entityId);

  const { showPreview } = useTransactionActions();
  const { showTakePaymentForm, showAddPaymentMethodForm } = usePaymentMethods();

  if (isLoading) {
    return <LoadingPlaceholder />;
  }

  return (
    <CustomerLayout>
      <div className={"py-3"}>
        <h4 className={"text-heading4-600 md:hidden"}>Billing</h4>
        <div className="mb-8 flex flex-col gap-y-5 pt-5 md:grid md:grid-cols-3 md:gap-8">
          <div className="md:col-span-2">
            <BaseCard
              bodySlot={
                <BalanceIndicator balance={balance} direction={"vertical"} />
              }
              rightSlot={
                <Button
                  variant={"brand"}
                  text={"Make payment"}
                  size={"sm"}
                  onClick={() =>
                    showTakePaymentForm(account?.entityId || "", balance)
                  }
                />
              }
              padding={4}
            />
          </div>
          <SummaryCard
            icon={"card"}
            text={"Payment methods"}
            onAddClick={() => showAddPaymentMethodForm(account?.entityId || "")}
            onCardClick={() => Router.push("CustomerPaymentMethods")}
          />
        </div>
        <div className={"flex flex-col space-y-4 px-5"}>
          <h5 className={"py-5 text-heading5-600"}>Account statement</h5>
          {transactions.length === 0 ? (
            <ContentPlaceholder
              icon="helpCircleOutline"
              title="Nothing to show here"
              description="Nothing has been billed on this account yet."
            />
          ) : (
            transactions.map(transaction => (
              <TransactionSummaryCell
                key={transaction.transaction.id}
                balance={balances[transaction.transaction.id]}
                transaction={transaction}
                showPreview={showPreview}
              />
            ))
          )}
        </div>
      </div>
    </CustomerLayout>
  );
};
