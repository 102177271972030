import { ActionMenuHeader } from "./ActionMenuHeader";
import { ActionMenuItemContainer } from "./ActionMenuItemContainer";
import { ActionMenuSpacer } from "./ActionMenuSpacer";
import { useGenericComponents } from "../GenericComponentsProvider";
import { ActionMenuItem, ProtectedOverlayComponent } from "../interfaces";

export const ActionMenuItems = ({
  items,
  onActionPress,
  ProtectedOverlay,
  ClickableWrapper,
}: {
  items: ActionMenuItem[];
  onActionPress: () => void;
  ProtectedOverlay: ProtectedOverlayComponent;
  ClickableWrapper: React.FC<{
    onClick: (e: { stopPropagation: () => void }) => void;
    className: string;
    disabled?: boolean;
    style?: unknown[];
    children: React.ReactNode;
  }>;
}) => {
  const { View } = useGenericComponents();
  return (
    <View className={"flex flex-col"}>
      {items.map((item, index) => {
        // item.visible will be assumed to be true if not provided
        if (!(item.visible ?? true)) {
          return null;
        }
        if (item.type === "group-heading") {
          return (
            <View key={`group-${index}`}>
              <ActionMenuHeader title={item.text} />
            </View>
          );
        }
        if (item.type === "separator") {
          return (
            <View key={`separator-${index}`}>
              <ActionMenuSpacer />
            </View>
          );
        }
        if (item.type === "component") {
          return <View key={`component-${index}`}>{item.component}</View>;
        }
        if (item.type === "item") {
          return (
            <View key={item.text}>
              <ActionMenuItemContainer
                item={item}
                ProtectedOverlay={ProtectedOverlay}
                onActionPress={onActionPress}
                ClickableWrapper={ClickableWrapper}
              />
            </View>
          );
        }
      })}
    </View>
  );
};
