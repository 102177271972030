import { ActivityLogDto } from "@justraviga/classmanager-sdk";

import { ActivityLogItem } from "./ActivityLogItem";
import { useGenericComponents } from "../../../../GenericComponentsProvider";

interface Props {
  activityLogItems: ActivityLogDto[];
  goToStudent: (studentId: string) => void;
}

export const ActivityLogList = ({ activityLogItems, goToStudent }: Props) => {
  const { View, Pressable } = useGenericComponents();

  return (
    <View className={"space-y-2 -mx-2"}>
      {activityLogItems.map((item: ActivityLogDto) => (
        <Pressable onClick={() => goToStudent(item.entityId)} key={item.id}>
          <ActivityLogItem log={item} />
        </Pressable>
      ))}
    </View>
  );
};
