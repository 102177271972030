import { ActivityLogList } from "./activityLog/ActivityLogList";
import { getPlatformFunctions } from "../../../../platformSpecific";
import { useGenericComponents } from "../../../GenericComponentsProvider";

type Props = {
  goToStudent: (studentId: string) => void;
  viewAllActivities: () => void;
};

export const RecentActivityWidget = ({
  goToStudent,
  viewAllActivities,
}: Props) => {
  const { Text, BaseCard } = useGenericComponents();
  const { useApi } = getPlatformFunctions();

  const { data: activityLogItems, isLoading } = useApi("listActivityLog", {
    pageSize: 5,
  });

  if (isLoading) {
    return (
      <BaseCard
        collapsible={true}
        title={"Recent activity"}
        bodySlot={<Text>Loading...</Text>}
      />
    );
  }

  return (
    <BaseCard
      collapsible={true}
      title={"Recent activity"}
      headerAction={{
        text: "View all",
        onClick: viewAllActivities,
      }}
      bodySlot={
        <ActivityLogList
          activityLogItems={activityLogItems!.data}
          goToStudent={goToStudent}
        />
      }
    />
  );
};
